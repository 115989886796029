<template>
  <div>
    <KTCodePreview v-bind:title="'Cập nhật tài khoản'">
      <template v-slot:preview>
        <v-form ref="form" v-model="valid" lazy-validation>
          <div class="col-md-12 mb-5">
            <div class="row">
              <div class="col-md-3">
                <b-row class="my-1">
                  <label class="label-input">Tên tài khoản:</label>
                  <span class="text-danger">*</span>
                </b-row>
                <b-row class="my-1">
                  <b-input
                    id="input-small"
                    size="sm"
                    placeholder
                    v-model="$v.form.username.$model"
                    :state="validateState('username')"
                    append-icon="search"
                    single-line
                    hide-details
                    class="col-md-12 form-control-sm"
                    aria-describedby="input-live-help input-live-feedback"
                    :disabled="true"
                  ></b-input>
                  <b-form-invalid-feedback id="input-live-feedback">
                    Nhập tên tài khoản với ít nhất 3 ký tự!
                  </b-form-invalid-feedback>
                </b-row>
              </div>
              <div class="col-md-3">
                <b-row class="my-1">
                  <b-col>
                    <label class="label-input">Nhân sự</label>
                  </b-col>
                </b-row>
                <vue-autosuggest
                  class="border-radius-none"
                  aria-describedby="input-store-live-feedback"
                  :suggestions="filteredOptions"
                  @selected="onSelectedEmployee"
                  :limit="200"
                  @input="onInputChange"
                  :input-props="{
                    id: 'autosuggest__input',
                    placeholder: 'Chọn nhân sự',
                    style: 'border-radius:0px!important',
                  }"
                  :should-render-suggestions="
                    (size, loading) => size >= 0 && !loading
                  "
                  v-model="searchEmployee"
                >
                  <div
                    slot-scope="{ suggestion }"
                    style="display: flex; align-items: center"
                  >
                    <div style="{ display: 'flex', color: 'navyblue'}">
                      {{ suggestion.item.name }}
                    </div>
                  </div>
                </vue-autosuggest>
              </div>
              <div class="col-md-3">
                <b-row class="my-1">
                  <b-col>
                    <label class="label-input">Quyền</label>
                  </b-col>
                </b-row>
                <b-row class="my-1">
                  <b-col>
                    <b-form-select
                      size="sm"
                      id="input-2"
                      v-model="selectedRole"
                      :options="listRole"
                      required
                      value-field="id"
                      text-field="name"
                      disabled-field="notEnabled"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="null" disabled
                          >-- Chọn quyền --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                  </b-col>
                </b-row>
              </div>
              <div class="col-md-3">
                <b-row class="my-1">
                  <b-col>
                    <label class="label-input">Trạng thái</label>
                  </b-col>
                </b-row>
                <b-row class="my-1">
                  <b-col>
                    <b-form-select
                      size="sm"
                      id="input-3"
                      v-model="selectedStatus"
                      :options="listStatus"
                      required
                      value-field="id"
                      text-field="name"
                      disabled-field="notEnabled"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="null" disabled
                          >-- Chọn trạng thái --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </v-form>
      </template>

      <template v-slot:foot>
        <!-- <b-col lg="4" class="pb-2"> -->
        <b-button
          style="fontweight: 600; width: 70px"
          variant="primary"
          size="sm"
          type="submit"
          @click="updateUser"
          v-if="checkPermission('USER_UPDATE')"
          >Lưu</b-button
        >
        <!-- </b-col> -->
        <!-- <b-col lg="2" class="pb-2"> -->
        <router-link to="/users" tag="button">
          <b-button
            style="margin-left: 10px; font-weight: 600; width: 70px"
            variant="secondary"
            size="sm"
            >Hủy</b-button
          >
        </router-link>
        <!-- </b-col> -->
      </template>
    </KTCodePreview>
  </div>
</template>
<style>
.center-label label {
  margin-bottom: 0px;
}
input::-webkit-calendar-picker-indicator {
  display: none;
}

.label-input {
  font-weight: 500;
}
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { validationMixin } from 'vuelidate';
import { minLength, required } from 'vuelidate/lib/validators';
import ApiService from '@/core/services/api.service';
import localData from '../../../utils/saveDataToLocal';
import { xoa_dau } from './../../../utils/common';

export default {
  mixins: [validationMixin],
  data() {
    return {
      valid: true,
      form: {
        username: '',
        password: '',
      },
      selectedEmployee: null,
      selectedStatus: null,
      listStatus: [
        { id: 1, name: 'Hoạt động' },
        { id: 2, name: 'Khóa' },
      ],
      selectedRole: null,
      listRole: [],
      userId: 0,
      isNew: true,
      searchEmployee: '',
      filteredOptions: [],
      options: [
        {
          data: [],
        },
      ],
    };
  },
  validations: {
    form: {
      username: {
        required,
        minLength: minLength(3),
      },
    },
  },
  components: {
    KTCodePreview,
  },
  created() {
    this.fetchData();
    this.fetchDataEmployee();
  },
  mounted() {
    if (this.checkPermission('USER_UPDATE')) {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: 'Tài khoản', route: '/users' },
        { title: 'Danh sách tài khoản', route: '/users' },
        { title: 'Cập nhật tài khoản' },
      ]);
    } else {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: 'Tài khoản', route: 'users' },
        { title: 'Danh sách tài khoản', route: 'users' },
        { title: 'Thông tin tài khoản' },
      ]);
    }
    this.userId = this.$route.query.id;
    this.getUserById();
  },
  methods: {
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      }
    },
    updateUser: async function () {
      this.isNew = false;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const username = this.$v.form.username.$model;
      // const password = this.$v.form.password.$model;
      const employeeId = this.selectedEmployee;
      const roleId = this.selectedRole;
      let isActive = false;
      if (this.selectedStatus === 1) {
        isActive = true;
      }
      let data = {
        id: this.userId,
        username: username,
        employeeId: employeeId,
        roleId: roleId,
        isActive: isActive,
      };
      ApiService.setHeader();
      ApiService.post('user/update', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.$router.push({
              path: '/users',
            });
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    fetchData: async function () {
      this.listRole = [];
      let param = {
        page: 1,
        limit: 100,
      };
      let paramQuery = {
        params: param,
      };
      ApiService.setHeader();
      ApiService.query('role', paramQuery).then(({ data }) => {
        data.data.list_role.forEach((element) => {
          let item = {
            id: element.id,
            name: element.name,
          };
          this.listRole.push(item);
        });
      });
    },
    getUserById: function () {
      ApiService.setHeader();
      ApiService.get(`user/${this.userId}`)
        .then(({ data }) => {
          if (data.status === 1) {
            this.form.username = data.data.username;
            if (data.data.isActive === false) {
              this.selectedStatus = 2;
            } else {
              this.selectedStatus = 1;
            }
            this.selectedEmployee = data.data.employeeId;
            this.searchEmployee = data.data.employeeName;
            this.selectedRole = data.data.roleId;
          } else {
            this.makeToastFaile('Tải dữ liệu thất bại!');
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    fetchDataEmployee: async function () {
      ApiService.query('employees/getAll')
        .then((response) => {
          const listEmployee = response.data.data;
          this.options[0].data = listEmployee.map((item) => {
            const nameNoneSign = xoa_dau(item.fullName);
            return {
              id: item.id,
              name: item.fullName,
              nameNoneSign: nameNoneSign,
            };
          });

          this.filteredOptions = [
            {
              data: this.options[0].data,
            },
          ];
        })
        .catch(() => {});
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    onSelectedEmployee(option) {
      this.searchEmployee = option.item.name;
      this.selectedEmployee = option.item.id;
    },
    onInputChange(text) {
      if (text === '') {
        this.inputSearchProductProp.disabled = true;
      }
      this.searchStore = text;
      const filteredData = this.options[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
      //this.onChangeStore();
    },
  },
};
</script>
